import React from "react";

function Loader({ width }) {
  const w = 100 / width;
  return (
    <>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
      <div className="flex mt-4 item-center gap-x-2 w-full my-10">
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
        <p className={`w-[16%] h-2 bg-gray-200 rounded-lg `}></p>
      </div>
    </>
  );
}

export default Loader;
