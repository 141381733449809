import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../Components/Toasts/toasts";

export const addPayment = createAsyncThunk(
  "payment",
  async (paymentData, thunkAPI) => {
    try {
      //   console.log("paymentData : ", paymentData);
      let URL = `https://yeshewabirhan.abyssiniasoftware.com/api/tickets`;

      let response = await axios.post(URL, paymentData, {
        headers: {
          Authorization: `Bearer CHAPUBK_TEST-YSUqvu7YzxfVxaL9ZjGzgu2o3fMgQIL4`,
        },
      });
      console.log("response : ", response);
      if (response.status === 201) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError("loading...");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
