import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import BusMaintenance from "./Pages/Bus/busMaintenance";
import SearchResult from "./Pages/Bus/searchResult";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Signup from "./Pages/User/signUP";
import Login from "./Pages/User/login";
import Invoicer from "./Pages/Invoicer/invoicer";
import Ticketing from "./Pages/Ticketing/Ticketing";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<BusMaintenance />}></Route>
        {/* <Route
          path="/search"
          render={(props) => (
            <SearchResult key={props.match.params} {...props} />
          )}
        /> */}
        <Route
          path="/search/:from/:to/:date"
          element={<SearchResult />}
        ></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/invoicer" element={<Invoicer />}></Route>
        <Route path="/singleTickets/:id" element={<Ticketing />}></Route>
      </Routes>
    </>
  );
}

export default App;
