import { createSlice } from "@reduxjs/toolkit";

import { loginUser } from "../../Action/User-Action/UserAction";

// initial state
const initialState = {
  loading: false,
  users: [],
  user: {},
  isLogin: false,
  error: false,
  success: false,
  message: null,
};

// users slice
const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.success = true;
        state.error = false;
        state.isLogin = true;
        state.message = action.message;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.message;
        state.success = false;
        state.isLogin = false;
      });
  },
});
// export
export default usersSlice.reducer;
