import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { singleTicketing } from "../../Action/Ticketing-Action/TicketingAction";
const initialState = {
  ticketing: {},
  ticketing: [],
  loading: false,
  error: null,
  success: false,
};

const ticketingSlice = createSlice({
  reducers: {},
  name: "ticketing",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singleTicketing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(singleTicketing.fulfilled, (state, action) => {
        state.loading = false;
        state.ticketing = action.payload;
      })
      .addCase(singleTicketing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ticketingSlice.reducer;
