import { data } from "autoprefixer";
import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";

const Timer = ({ countDate }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState();

  let interval = useRef();
  const startTimer = () => {
    console.log("passed Date : ", countDate);
    const countdownDate = new Date(countDate).getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <>
      <div className="w-full h-auto flex flex-col lgl:flex-row justify-center">
        <div className="w-full h-full bg-gradient-to-b from-gray-200 to-gray-300 p-8 rounded-lg shadow-shadowTwo flex flex-col md:flex-row lgl:flex-col gap-8 justify-center items-center">
          <div className=" justify-center items-center text-center">
            <h3 className="text-xl font-bold text-[#32817A]">
              {" "}
              BUS DEPARTURE TIME
            </h3>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div>
                <span className="bannerIcon ml-4">{timerDays}</span>
                <h2 className="text-base uppercase font-titleFont m-2">Days</h2>
              </div>
              <div>
                <span className="bannerIcon ml-4">{timerHours}</span>
                <h2 className="text-base uppercase font-titleFont m-2">
                  Hours
                </h2>
              </div>
              <div>
                <span className="bannerIcon ml-4">{timerMinutes}</span>
                <h2 className="text-base uppercase font-titleFont m-2">
                  Minutes
                </h2>
              </div>
              <div>
                <span className="bannerIcon ml-4">{timerSeconds}</span>
                <h2 className="text-base uppercase font-titleFont m-2">
                  Seconds
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Timer;
