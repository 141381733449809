import React from "react";
import busIcon from "../../Assets/Images/Bus-image/icons8-bus-66.png";
import backgroundImage from "../../Assets/Images/Bus-image/menbie.jpg";
const Loading = () => {
  return (
    <>
      <div class="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
        <div class="flex justify-center items-center mt-[50vh]">
          <div class="absolute animate-spin rounded-full h-48 w-48 border-t-4 border-l-2 border-r-2 border-b-4 border-[#1ec6b6] flex justify-center"></div>
          <img
            src={busIcon}
            class="rounded-full h-28 w-28 animate-trans-loading"
          />
          {/* <img
            src={backgroundImage}
            class="absolute rounded-full h-24 w-48 bottom-0"
          /> */}
        </div>
      </div>
    </>
  );
};

export default Loading;
