import { React, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";

const Invoicer = () => {
  const [isSelected, setIsSelected] = useState(false);
  const LetterRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => LetterRef.current,
    documentTitle: "current-letter",
    onAfterPrint: () => alert("print success"),
  });
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const { payment, success } = useSelector((state) => state.payment);
  //  console.log("Invoicer information : ", payment.amount);
  return (
    <>
      <div className="relative bg-[#F0FDFA]">
        <div className="flex justify-center items-center">
          <main className="m-5 p-5 xl:grid grid-cols-2 gap-10 xl:items-start w-[1280px] scale-75 ">
            <div className="bg-white p-0 rounded-2xl border-4 border-blue-200 w-full">
              <button
                className="bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded hover:bg-blue-600 hover:text-white transition-all duration-150 hover:ring-4 hover:ring-blue-400"
                onClick={handlePrint}
              >
                Print / Download
              </button>

              <div ref={LetterRef} className="p-5">
                <header className="flex flex-col items-center justify-center mb-5 xl:flex-row xl:justify-between">
                  <div>
                    <h1 className="font-bold uppercase tracking-wide text-4xl mb-3">
                      Invoicer
                    </h1>
                  </div>
                </header>

                <section className="flex flex-col items-end justify-end">
                  <h2 className="font-bold text-xl uppercase mb-1">
                    D4D EXPRESS
                  </h2>
                  <p>Talga : 87492</p>
                  <p>Seat No : 25</p>
                </section>

                <section className="mt-4">
                  <h2 className="text-md uppercase font-bold mb-1">
                    From : Bahir Dar - Gonder
                  </h2>
                  <p>Arrival Time : 2:30</p>
                </section>

                <article className="mt-4 mb-10 flex items-end justify-end">
                  <ul>
                    <li className="p-1 ">
                      <span className="font-bold">Invoicer number:</span> 12123
                    </li>
                    <li className="p-1 bg-gray-100">
                      <span className="font-bold">Invoice date:</span>{" "}
                      10/31/2023
                    </li>
                    <li className="p-1 ">
                      <span className="font-bold">Due date:</span> 3/2/5
                    </li>
                  </ul>
                </article>

                <table width="100%" className="mb-10">
                  <thead>
                    <tr className="bg-gray-100 p-1">
                      <td className="font-bold">User Name</td>
                      <td className="font-bold">Quantity</td>
                      <td className="font-bold">Price</td>
                      <td className="font-bold">Amount</td>
                    </tr>
                  </thead>
                  {/* {list.map(({ id, description, quantity, price, amount }) => (
                <React.Fragment key={id}> */}
                  <tbody>
                    <tr className="h-10">
                      <td>{userInfo?.firstName || "abebe"}</td>
                      <td>2</td>
                      <td>173</td>
                      <td>346</td>
                    </tr>
                  </tbody>
                  {/* </React.Fragment> */}
                  {/* ))} */}
                </table>

                <div>
                  <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
                    Total. {(346).toLocaleString()}
                  </h2>
                </div>
                <div>
                  <footer className="footer border-t-2 border-gray-300 pt-5">
                    <ul className="flex flex-wrap items-center justify-center">
                      <li>
                        <span className="font-bold">Your name:</span>{" "}
                        {userInfo?.firstName || "abebe"}
                      </li>
                      <li>
                        <span className="font-bold">Your email:</span>{" "}
                        {userInfo?.email || "dtls23@gmail.com"}
                      </li>
                      <li>
                        <span className="font-bold">Phone number:</span>{" "}
                        {userInfo?.phoneNumber || "+2519 26189749"}
                      </li>
                      <li>
                        <span className="font-bold">Bank:</span>Digital
                        Transport and Logistic solution
                      </li>
                      <li>
                        <span className="font-bold">Account holder:</span> cbe
                      </li>
                      <li>
                        <span className="font-bold">Account number:</span>{" "}
                        100007896342
                      </li>
                      <li>
                        <span className="font-bold">Website:</span>{" "}
                        <a href="#" target="_blank" rel="noopenner noreferrer">
                          www.eplusapp.et
                        </a>
                      </li>
                    </ul>
                  </footer>

                  <p className="text-center px-5 mt-8 text-xs ">
                    Invoicer is built by{" "}
                    <a
                      href="https://tsbsankara.com"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      DTLS
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Invoicer;
