import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../Components/Toasts/toasts";

export const singleTicketing = createAsyncThunk(
  "ticketing",
  async (id, thunkAPI) => {
    try {
      let response = await axios.get(
        `https://yeshewabirhan.abyssiniasoftware.com/api/routes/${id}`,
        id
      );
      if (response.status === 200) {
        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      handleError(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
