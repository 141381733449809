import React from "react";
import {
  RiBus2Line,
  RiClockwise2Line,
  RiCloseCircleLine,
  RiGlobalLine,
  RiHotelBedLine,
  RiStarFill,
  RiTimeLine,
  RiTrainLine,
} from "react-icons/ri";

const Items = () => {
  return (
    <div className="p-20 w-full  flex flex-col justify-center items-center ">
      <div className="flex flex-row items-center justify-center mx-3 lg:mx-0 flex-wrap gap-3 max-w-[1200px]">
        <article className="overflow-hidden rounded-lg  transition  md:w-96 flex items-center flex-col">
          {/* <img
            alt="Office"
            src="https://preview.colorlib.com/theme/travelo/img/place/1.png"
            className="h-56 w-full object-cover"
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-32 text-pink-700  text-center"
            viewBox="0 0 64 64"
            id="bus"
          >
            <path
              fill="rgb(190 24 93 )"
              d="M18.09 42A2.92 2.92 0 1 0 21 44.91 2.92 2.92 0 0 0 18.09 42Zm0 3.83a.92.92 0 1 1 .91-.92.92.92 0 0 1-.91.92ZM46 42.24a2.92 2.92 0 1 0 2.91 2.91A2.92 2.92 0 0 0 46 42.24Zm0 3.83a.92.92 0 1 1 .91-.92.92.92 0 0 1-.91.92ZM55.5 16a3.37 3.37 0 0 0-1.49.35A6 6 0 0 0 48.26 11H15.74A6 6 0 0 0 10 16.35 3.37 3.37 0 0 0 8.5 16 3.5 3.5 0 0 0 5 19.5v2A3.5 3.5 0 0 0 8.5 25a3.43 3.43 0 0 0 1.5-.33V56a1 1 0 0 0 1 1h8.61a1 1 0 0 0 1-1v-3.22h22.82V56a1 1 0 0 0 1 1H53a1 1 0 0 0 1-1V24.67a3.43 3.43 0 0 0 1.5.33 3.5 3.5 0 0 0 3.5-3.5v-2a3.5 3.5 0 0 0-3.5-3.5ZM52 21.93v13H33V18h19v3.93ZM19.57 50.78h-4.84A2.78 2.78 0 0 1 12 48V36.91h40V48a2.78 2.78 0 0 1-2.77 2.78H19.57ZM12 21.93V18h19v16.91H12ZM15.74 13h32.52a4 4 0 0 1 3.65 3H12.09a4 4 0 0 1 3.65-3ZM8.5 23A1.5 1.5 0 0 1 7 21.5v-2a1.5 1.5 0 0 1 3-.34v2.68A1.5 1.5 0 0 1 8.5 23Zm10.07 32H12v-3.11h.06l.19.11a5.86 5.86 0 0 0 .55.3l.26.11a5.58 5.58 0 0 0 .58.18l.26.06a4.93 4.93 0 0 0 .87.09h3.84ZM52 55h-6.57v-2.22h3.84a4.93 4.93 0 0 0 .87-.09l.26-.06a5.58 5.58 0 0 0 .58-.18l.26-.11a5.86 5.86 0 0 0 .55-.3l.19-.11h.06Zm5-33.5a1.5 1.5 0 0 1-3 .34v-2.68a1.5 1.5 0 0 1 3 .34Z"
              data-name="2-bus"
            ></path>
          </svg>

          <div className="bg-white  sm:py-2">
            <h3 className=" text-2xl text-gray-900 pr-2 text-center">
              Comfortable Journey
            </h3>
            <p className="text-center text-gray-600">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Veritatis, earum.
            </p>
          </div>
        </article>
        <article className="overflow-hidden rounded-lg  transition  md:w-96 flex items-center flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 64 64"
            viewBox="0 0 64 64"
            id="bed"
            className="w-32 text-pink-700  text-center"
          >
            <path
              fill="rgb(190 24 93 )"
              d="M58,42C58,42,58,42,58,42l0-4.9c0-2.2-1.3-3.9-3-4.1V16c0-1.7-1.3-3-3-3H12c-1.7,0-3,1.3-3,3v17
		c-1.7,0.2-3,1.9-3,4.1V42c0,0,0,0,0,0c-0.6,0.6-1,1.5-1,2.4v1.1c0,1.7,1.3,3.2,3,3.4v1c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-1h40v1
		c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-1c1.7-0.2,3-1.7,3-3.4v-1.1C59,43.5,58.6,42.6,58,42z M11,16c0-0.6,0.4-1,1-1h40
		c0.6,0,1,0.4,1,1v17h-2v-4.3c0-2.1-1.7-3.7-3.7-3.7h-8.5c-2.1,0-3.7,1.7-3.7,3.7V33h-6v-4.3c0-2.1-1.7-3.7-3.7-3.7h-8.5
		c-2.1,0-3.7,1.7-3.7,3.7V33h-2V16z M49,33H37v-4.3c0-1,0.8-1.7,1.7-1.7h8.5c1,0,1.7,0.8,1.7,1.7V33z M27,33H15v-4.3
		c0-1,0.8-1.7,1.7-1.7h8.5c1,0,1.7,0.8,1.7,1.7V33z M9.4,35H10h4h14h8h14h4h0.6c0.6,0,1.4,0.9,1.4,2.1V41c-0.1,0-0.3,0-0.4,0H8.4
		c-0.2,0-0.3,0-0.4,0v-3.9C8,35.9,8.7,35,9.4,35z M57,45.6c0,0.8-0.7,1.4-1.4,1.4H8.4C7.7,47,7,46.3,7,45.6v-1.1
		C7,43.7,7.7,43,8.4,43h47.1c0.8,0,1.4,0.7,1.4,1.4V45.6z"
            ></path>
          </svg>
          <div className="bg-white  sm:py-2">
            <h3 className=" text-2xl text-gray-900 pr-2 text-center">
              Luxuries Hotel
            </h3>
            <p className="text-center text-gray-600">
              A wonderful serenity has taken to the possession of my entire
              soul.
            </p>
          </div>
        </article>
        <article className="overflow-hidden rounded-lg  transition  md:w-96 flex items-center flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 512 512"
            className="w-32 text-pink-700  text-center"
            viewBox="0 0 512 512"
            id="globe"
          >
            <path
              fill="rgb(190 24 93 )"
              d="M16.7 508.6c14.9 0 29.8 0 44.6 0 35.7 0 71.5 0 107.2 0 43 0 86.1 0 129.1 0 37.4 0 74.7 0 112.1 0 18.1 0 36.3.7 54.4 0 .2 0 .5 0 .7 0 9.7 0 9.7-15 0-15-14.9 0-29.8 0-44.6 0-35.7 0-71.5 0-107.2 0-43 0-86.1 0-129.1 0-37.4 0-74.7 0-112.1 0-18.1 0-36.3-.7-54.4 0-.2 0-.5 0-.7 0C7 493.6 7 508.6 16.7 508.6L16.7 508.6zM365.7 324.1c-56.7 49.2-140.3 58.3-206.1 22C93.3 309.6 55.6 232.8 68.2 158c7-41.7 28.2-79.3 59.9-107.2 7.3-6.4-3.4-17-10.6-10.6C56.7 93.8 34.4 182.4 64.1 258.2c15.1 38.5 42 71.6 76.7 94.1 33 21.3 72.5 32.2 111.8 31.1 45.7-1.3 89.3-18.8 123.8-48.7C383.6 328.4 373 317.8 365.7 324.1L365.7 324.1z"
            ></path>
            <path
              fill="rgb(190 24 93 )"
              d="M105.1 312.2c-5.8 5.8-11.9 11.3-16.7 17.9-8.1 10.9-12.4 24.8-12 38.4.5 18.6 9.2 33.9 22 46.8 22.8 22.8 45.6 45.6 68.4 68.4 7.6 7.6 15.1 15.1 22.7 22.7 6.8 6.8 17.4-3.8 10.6-10.6-17.8-17.8-35.5-35.5-53.3-53.3-10.8-10.8-21.6-21.6-32.5-32.5-8.4-8.4-16.8-16.2-20.6-27.9-4.2-13-2.8-27.2 4.1-39 4.5-7.8 11.6-13.9 17.9-20.2C122.6 316 111.9 305.4 105.1 312.2L105.1 312.2zM416.9 177.4c-.3 65.2-41.6 125.6-103.3 147.9-62.6 22.7-133.7 3.1-176.1-48.2-42-50.8-46.9-125.1-12.8-181.3 34-56 101.3-86 165.7-72.9 64.2 13 114.4 65.6 124.8 130.2C416.4 161.2 416.9 169.3 416.9 177.4c0 9.6 15 9.7 15 0-.3-71.8-45.7-136.9-113.1-162-67.6-25.1-146.6-3.6-192.4 52.1-45.9 56-52.4 136.8-14.8 199 37.6 62.1 111.6 94.2 182.6 79.8 69.2-14 124.9-72.2 135.4-142.1 1.3-8.9 2.1-17.9 2.1-26.9C432 167.8 417 167.8 416.9 177.4z"
            ></path>
            <path
              fill="rgb(190 24 93 )"
              d="M158 308.3c5.8-5.8 12-11.5 17.5-17.6 6.7-7.3 9.5-17.4 7-27.2-.9-3.5-3.1-6.6-3.5-10.3-.4-3.7.8-7.6 3.2-10.4 2.1-2.5 5-4.5 7.5-6.5 4.5-3.6 9-7.3 13.5-10.9 7-5.6 15.9-11.1 21.2-18.6 8.8-12.5 5.5-28.2-4.8-38.5-7.5-7.5-15-15-22.4-22.4-6.4-6.4-15.8-13.4-11.3-23.8 2-4.6 7.1-8.4 10.6-11.9 4.4-4.4 8.5-8.6 10.8-14.5 3.8-10 1.4-21.5-5.6-29.5-2.6-2.9-5.4-5.5-8.2-8.2-7.4-7.4-14.7-14.7-22.1-22.1-6.8-6.8-17.4 3.8-10.6 10.6 6 6 12.1 12.1 18.1 18.1 5.9 5.9 16.1 13 15.1 22.3-.6 5.6-4.5 8.9-8.2 12.6-3.6 3.6-7.7 7.1-10.7 11.3-9 12.5-5.8 28.3 4.6 38.7 7.3 7.3 14.5 14.5 21.8 21.8 5.8 5.8 14.5 11.9 12.9 21.2-1.4 8.2-12 14.2-18 19-7.3 5.9-14.8 11.7-22 17.7-6.3 5.3-10.1 12.8-10.5 21-.3 5.3 1.4 9.7 3.2 14.5 1.4 3.6 1.7 7.3.2 11-1.9 4.6-6.8 8.3-10.2 11.7-3.3 3.3-6.6 6.6-9.9 9.9C140.5 304.5 151.1 315.1 158 308.3L158 308.3zM353.2 40.2c-11 11-22.4 21.7-33 33-5.4 5.8-7.9 13.2-6.4 21 .6 3 2 5.9 3.1 8.7 3.4 8.7 6.9 17.4 10.2 26.2 1.5 4.1 1.4 7.3-1.7 10.6-3.2 3.4-6.6 6.6-9.9 9.9-4 4-7.7 7.9-9.1 13.6-2 8.1.6 16.5 6.5 22.3 5.8 5.7 11.9 12 18.6 16.6 8 5.5 18.9 5.1 26.6-.7 4.3-3.3 9.3-7.9 14.3-2.4 2.1 2.3 3 5.5 5.1 7.9 2.9 3.4 7 5.9 11.3 7 8 2.1 16.1-.4 22-6 6.4-6 12.5-12.5 18.7-18.7 6.8-6.8-3.8-17.4-10.6-10.6-5.5 5.5-11.1 11.1-16.6 16.6-2.3 2.3-4.5 4.7-8.2 4.5-5.9-.4-7.1-6.6-10.4-10.4-4.6-5.3-11.8-8.4-18.8-7.8-3.5.3-6.9 1.3-9.9 3-3.1 1.8-5.4 5.4-9 6.3-4.7 1.1-7.5-2.2-10.6-5-3.4-3.1-6.8-6.2-10.2-9.3-3.1-2.8-5.9-6.1-4.1-10.7 1.2-3.1 4.8-5.7 7.1-7.9 8.5-8.5 17.7-17 14-30.4-2-7.2-5.4-14.2-8.2-21.1-2.4-6.2-8.9-16.4-3.7-22.4 5-5.7 10.9-10.9 16.2-16.2 5.7-5.7 11.3-11.3 17-17C370.6 43.9 360 33.3 353.2 40.2L353.2 40.2z"
            ></path>
            <path
              fill="rgb(190 24 93 )"
              d="M338.1,234.5c6.1-11,0.9-25.7-11.9-28.7c-3.8-0.9-8-1-11.9-1.4c-15.7-1.6-31.3-3.3-47-4.9c-11.7-1.2-23-4.1-32,5.9
					c-9.5,10.5-18.9,21-28.4,31.6c-5.5,6.1-8.6,12.9-6.2,21.3c1.7,6,4,11.8,6,17.7c2,5.9,3.8,11.8,6,17.7c4,10.8,15.9,17,26.7,11.7
					c4-2,6.3-6.2,11-3.4c2.9,1.8,5.7,3.9,8.6,5.9c8.1,5.5,16.8,9.1,26,2.9c9.9-6.7,18.8-14.9,28.8-21.4c2.8-1.8,4.7-1.1,7.6-0.3
					c3.8,1,7.5,2.1,11.3,3.1c10.4,2.9,21.8-0.2,25.7-11.4c3.4-9.8-1.1-20.6-10.5-24.9c-6.6-3-13.2-5.8-19.8-8.7
					c-1.8-0.8-3.7-1.6-5.5-2.4c0.9,3.4,1.8,6.8,2.7,10.3C329.5,248.3,333.8,241.4,338.1,234.5c5.1-8.2-7.8-15.7-13-7.6
					c-4.3,6.9-8.6,13.8-12.9,20.6c-2,3.2-0.9,8.7,2.7,10.3c4.7,2.1,9.4,4.1,14.1,6.2c2.9,1.3,5.9,2.6,8.8,3.9c1.6,0.7,4,1.4,5.3,2.6
					c1.9,1.8,2,5.4,0,7.2c-2,1.8-4.6,0.7-6.8,0c-6-1.7-12.8-4.6-19.1-4.7c-7,0-11.6,3.9-16.8,7.7c-5.7,4.2-11.4,8.5-17.1,12.7
					c-3.5,2.6-7.5,7.2-12,4.6c-4.7-2.7-8.9-6.4-13.5-9.2c-4.6-2.8-10.1-3.9-15.3-2.5c-2.7,0.7-5.1,2.1-7.3,3.8
					c-1.5,1.2-2.7,2.4-4.8,2c-3.8-0.8-4.5-6.7-5.5-9.6c-2.3-6.7-4.5-13.4-6.8-20c-1.1-3.3-4.5-9.4-2.4-12.7c2.1-3.3,5.6-6.2,8.2-9.1
					c4.9-5.4,9.8-10.9,14.7-16.3c2.9-3.2,5.6-6.8,8.8-9.7c2.7-2.5,6.1-1.5,9.4-1.2c14.8,1.6,29.5,3.1,44.3,4.7
					c6.2,0.7,12.5,1.3,18.7,2c1.1,0.1,2.2,0.1,3.3,0.3c-1.9-0.4,0.4,0.2,0.5,0.3c0.7,0.5,1.3,0.8,1.8,1.5c1.1,1.6,0.8,3.2-0.1,4.8
					C320.5,235.4,333.5,243,338.1,234.5z"
            ></path>
          </svg>

          <div className="bg-white  sm:py-2">
            <h3 className=" text-2xl text-gray-900 pr-2 text-center">
              Travel Guide
            </h3>
            <p className="text-center text-gray-600">
              A wonderful serenity has taken to the possession of my entire
              soul.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Items;
