import { createSlice } from "@reduxjs/toolkit";
import { addPayment } from "../../Action/Payment-Action/PaymentAction";

// initial state
const initialState = {
  loading: false,
  //payment: [],
  payment: {},
  error: false,
  success: false,
  message: null,
};

// eplusapp stock slice
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.payment = action.payload;
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export
export default paymentSlice.reducer;
