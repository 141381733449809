import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../Components/Toasts/toasts";

export const singleTracking = createAsyncThunk("tracking", async (thunkAPI) => {
  try {
    console.log("tracking action page");
    let response = await axios.get(
      `https://bus.abyssiniasoftware.com/location/get_location?phone=940637672`
    );
    console.log("employee Data : ", response);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("error : ", response.message);
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    handleError("loading...");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});
