// import { createStore } from 'redux'
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../../States/Reducer/Bus-Reducer/terminal.js";
import PaymentReducer from "../../States/Reducer/Payment-Reducer/PaymentReducer.js";
import UserReducer from "../../States/Reducer/User-Reducer/UserReducer.js";
import AssignationReducer from "../../States/Reducer/Assignation-Reducer/AssignationReducer.js";
import TrackingReducer from "../../States/Reducer/Tracking-Reducer/TrackingReducer.js";
import TicketingReducer from "../../States/Reducer/Ticketing-Reducer/TicketingReducer.js";

const store = configureStore({
  reducer: {
    buses: counterReducer,
    payment: PaymentReducer,
    user: UserReducer,
    assignation: AssignationReducer,
    tracking: TrackingReducer,
    ticketing: TicketingReducer,
  },
});

export default store;
