import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button1 from "../../Components/Buttons/tealButton";
import Button from "../../Components/Buttons/redButton";
import Input from "../../Components/Text-Input";
import Layout from "../../Layouts/Navigation/layout";
import { Hero } from "../../Layouts/Navigation/Hero";
import PopularDestination from "../../Layouts/Navigation/popularDestination";
import Subscribe from "../../Layouts/Navigation/Subscribe";
import PopularPlaces from "../../Layouts/Navigation/popularPlaces";
import Search from "../../Layouts/Navigation/Search";
import RecentTrips from "../../Layouts/Navigation/recentTrips";
import { Review } from "../../Layouts/Navigation/Review";
import Items from "../../Layouts/Navigation/Items";
//import Trips from "../Trips/trips";
import BackTotop from "../BackToTop/BackToTop";
const BusMaintenance = () => {
  return (
    <Layout>
      <Hero />
      <RecentTrips />
      <Items />
      <Review />
      <PopularDestination />
      <BackTotop />
    </Layout>
  );
};
export default BusMaintenance;
