import React, { useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";

const RecentTrips = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "start" },
    [Autoplay({ stopOnInteraction: false })]
  );
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  return (
    <div className="px-3 py-14 w-full  flex flex-col justify-center items-center ">
      <h1 className="text-center font-normal text-4xl mb-16">Recent Trips</h1>
      <div className="grid grid-cols-1 lg:grid-cols-4 items-center lg:items-start justify-center flex-wrap gap-4 px-5 md:px-0 max-w-[1200px]">
        <div
          className="overflow-hidden relative  md:col-span-3 "
          style={{
            flex: "0 0 100%",
          }}
          ref={emblaRef}
        >
          <div className="flex   text-white">
            <div className="border min-w-0  flex-2  lg:flex-3  h-full  flex items-center justify-center mx-2 ">
              <article className="overflow-hidden rounded-lg  transition w-full md:w-96">
                <img
                  alt=""
                  src="https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcT-q86AIzbYuuoMxlcJv6wV_S9eGUkogO4o9nVUQ28ODUsqV39god7ffu8sAFSi4AO2b6wLMt_An2hVjc0cd88-GpJSq7vyKqlfs8RyxQ"
                  className="h-56 w-full object-cover"
                />

                <div className="bg-white  sm:py-2">
                  <p className="text-gray-600">October 13 , 2019</p>

                  <h3 className=" text-xl text-gray-900 pr-2">
                    Gonder city: Historic Ethiopian city known for its royal
                    castles.
                  </h3>
                </div>
              </article>{" "}
            </div>
            <div className="border min-w-0  flex-2  lg:flex-3 h-full  flex items-center justify-center mx-2 ">
              <article className="overflow-hidden rounded-lg  transition w-full md:w-96">
                <img
                  alt="Office"
                  src="https://lh5.googleusercontent.com/p/AF1QipOPjrY8-WKNsVC4p1hsLXG2fxWbwd6HusfxnX53=w675-h390-n-k-no"
                  className="h-56 w-full object-cover"
                />

                <div className="bg-white  sm:py-2">
                  <p className="text-gray-600">October 13 , 2019</p>

                  <h3 className=" text-xl text-gray-900 pr-2">
                    Desse: Ethiopian city known for welcoming and lovely people.
                  </h3>
                </div>
              </article>
            </div>
            <div className="border min-w-0  flex-2  lg:flex-3 h-full  flex items-center justify-center mx-2 ">
              <article className="overflow-hidden rounded-lg  transition w-full md:w-96">
                <img
                  alt="Office"
                  src="https://lh5.googleusercontent.com/p/AF1QipOPjrY8-WKNsVC4p1hsLXG2fxWbwd6HusfxnX53=w675-h390-n-k-no"
                  className="h-56 w-full object-cover"
                />

                <div className="bg-white  sm:py-2">
                  <p className="text-gray-600">October 13 , 2019</p>

                  <h3 className=" text-xl text-gray-900 pr-2">
                    Desse: Ethiopian city known for welcoming and lovely people.
                  </h3>
                </div>
              </article>
            </div>
            <div className="border min-w-0  flex-2  lg:flex-3 h-full  flex items-center justify-center mx-2 ">
              <article className="overflow-hidden rounded-lg  transition w-full md:w-96">
                <img
                  alt="Office"
                  src="https://lh5.googleusercontent.com/p/AF1QipOPjrY8-WKNsVC4p1hsLXG2fxWbwd6HusfxnX53=w675-h390-n-k-no"
                  className="h-56 w-full object-cover"
                />

                <div className="bg-white  sm:py-2">
                  <p className="text-gray-600">October 13 , 2019</p>

                  <h3 className=" text-xl text-gray-900 pr-2">
                    Desse: Ethiopian city known for welcoming and lovely people.
                  </h3>
                </div>
              </article>
            </div>
            <div className="border min-w-0  flex-2  lg:flex-3 h-full  flex items-center justify-center mx-2 ">
              <article className="overflow-hidden rounded-lg  transition w-full md:w-96">
                <img
                  alt="Office"
                  src="https://lh5.googleusercontent.com/p/AF1QipOPjrY8-WKNsVC4p1hsLXG2fxWbwd6HusfxnX53=w675-h390-n-k-no"
                  className="h-56 w-full object-cover"
                />

                <div className="bg-white  sm:py-2">
                  <p className="text-gray-600">October 13 , 2019</p>

                  <h3 className=" text-xl text-gray-900 pr-2">
                    Desse: Ethiopian city known for welcoming and lovely people.
                  </h3>
                </div>
              </article>
            </div>
          </div>
          <button
            className="embla__prev absolute top-[45%] left-0 py-2 md:py-4 rounded-full px-2 md:px-4 hover:opacity-100 transition-all text-black   bg-white border text-center"
            onClick={scrollPrev}
          >
            <RiArrowLeftLine className=" " />
          </button>
          <button
            className="embla__next absolute top-[45%] right-0 py-2 md:py-4 rounded-full px-2 md:px-4 hover:opacity-100 transition-all text-black   bg-white border"
            onClick={scrollNext}
          >
            <RiArrowRightLine className=" " />
          </button>
        </div>
        <article className="overflow-hidden rounded-lg  transition w-full md:w-72 border-2 bg-[#cfe1d0] border-green-900 flex flex-col justify-between h-full">
          <div className="  sm:py-2 px-3">
            <p className="text-gray-600">Sign up for </p>
            <h1 className="text-3xl">Best offers</h1>

            <h3 className=" text-md text-gray-900 pr-2">
              Subscribe to our newsletter and you will never miss out our
              special offers Find out about all the best flight and vacation
              package deals!
            </h3>
          </div>
          <div className="w-full flex justify-center items-center py-6 border-dotted border-t-2 border-black">
            <button className="py-2 bg-white px-4 rounded-full border-2 border-black">
              Subscribe
            </button>
          </div>
        </article>{" "}
      </div>
    </div>
  );
};

export default RecentTrips;
