import React from "react";
import { AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { FaSignInAlt, FaUserCheck } from "react-icons/fa";

const TopHeader = () => {
  return (
    <div
      style={{
        background: "#E5E7EB",
      }}
      className="hidden md:flex flex-wrap justify-around items-center mx-auto border-b-2 border-gray-300"
    >
      <div className="flex items-center py-3">
        <a
          href="tel:5541251234"
          className="mr-6 text-sm font-display font-medium hover:underline"
        >
          <BsTelephone className="h-4 w-4 inline-block text-[#0d9488]" />
          <span className="text-gray-500 hover:text-[#0d9488]">
            +2519 188 01 430
          </span>
        </a>
        <a
          href="mailto:info@eplusapp.et?subject=subject&cc=cc@eplusapp.et"
          className="mr-6 text-sm font-display font-medium hover:underline"
        >
          <AiOutlineMail className="h-4 w-4 inline-block mr-2 text-[#0d9488]" />
          <span className="text-gray-500 hover:text-[#0d9488]">
            {" "}
            info.dtls@gmail.com
          </span>
        </a>
      </div>
      <div className="flex items-center">
        <div className="flex justify-between item-center border-2 border-gray-300 p-1 space-x-4">
          <a
            href="http://localhost:3000/login"
            className="text-sm font-display font-medium"
          >
            <FaSignInAlt className="w-5 inline-block text-[#0d9488]" />
            <spa className="text-[#0d9488] hover:text-tealButton font-bold">
              Sign In
            </spa>
          </a>
          <a className="text-xs font-display font-medium">
            <span>/</span>
          </a>

          <a
            href="http://localhost:3000/signup"
            className="text-sm font-display font-medium"
          >
            <FaUserCheck className="w-5 inline-block text-[#0d9488]" />
            <span className="hover:text-[#0d9488]">Sing Up</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
