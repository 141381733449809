import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { singleAssignation } from "../../Action/Assignation-Action/AssignationAction";

const initialState = {
  assignation: {},
  assignation: [],
  loading: false,
  error: null,
  success: false,
};

const assignationSlice = createSlice({
  reducers: {},
  name: "assignation",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singleAssignation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(singleAssignation.fulfilled, (state, action) => {
        state.loading = false;
        state.assignation = action.payload;
      })
      .addCase(singleAssignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default assignationSlice.reducer;
