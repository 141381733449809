import React, { useState, useEffect } from "react";
import Layout from "../Layouts/Navigation/layout";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import HomeImg from "../Assets/Images/Company-logo/eplusapp_home.jpg";
import ContactImg from "../Assets/Images/User-image/contactImg.png";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";

const Contact = () => {
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // ========== Email Validation start here ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ========== Email Validation end here ================

  const handleSend = (e) => {
    e.preventDefault();
    if (username === "") {
      setErrMsg("Username is required!");
    } else if (phoneNumber === "") {
      setErrMsg("Phone number is required!");
    } else if (email === "") {
      setErrMsg("Please give your Email!");
    } else if (!emailValidation(email)) {
      setErrMsg("Give a valid Email!");
    } else if (subject === "") {
      setErrMsg("Plese give your Subject!");
    } else if (message === "") {
      setErrMsg("Message is required!");
    } else {
      setSuccessMsg(
        `Thank you dear ${username}, Your Messages has been sent Successfully!`
      );
      setErrMsg("");
      setUsername("");
      setPhoneNumber("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
  };

  // ,map functionality
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo",
    libraries: ["places"],
  });
  const [center, setCenter] = useState({
    lat: 11.585140284723675,
    lng: 37.37301319414389,
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [Msg, setMsg] = useState(null);
  useEffect(() => {
    (async () => {
      setMsg("Loading GPS...");
      if (navigator.geolocation) {
        let data = await navigator.geolocation.getCurrentPosition(
          async (postion) => {
            setTimeout(() => {
              setCenter({
                lat: postion.coords.latitude,
                lng: postion.coords.longitude,
                zoom: 18,
              });
              setMsg("");
            }, 1000);

            return {
              lat: postion.coords.latitude,
              lng: postion.coords.longitude,
            };
          }
        );
      } else alert("Please Allow your Location !");
    })();
  }, []);
  return (
    <Layout>
      <div className="relative overflow-hidden bg-no-repeat bg-contain  max-h-80">
        <img
          className="w-full h-screen object-cover"
          src={HomeImg}
          alt="eplus img"
        />
        <div
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
        >
          <div className="w-full xl:w-10/12 lg:w-10/12 md:w-11/12 mx-auto xl:mt-40 md:mt-32 mt-20">
            <div className="flex  justify-center items-center h-full">
              <div className="text-center text-white">
                <h1
                  className="text-4xl md:text-4xl xl:text-5xl text-primary font-bold font-display tracking-tight leading-tight"
                  style={{ fontFamily: "newone" }}
                >
                  Contact
                </h1>
                <p className="py-2">Digital Transport and Logistic solution</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 className="text-3xl text-center font-semibold text-gray-700 pt-10">
          Get in Touch
        </h1>
        <p className="text-md text-center font-sm text-gray-700 py-1">
          We are open for any suggestion or just to have a chat
        </p>
      </div>
      <section className="w-11/12 xl:w-11/12 mx-auto py-20 border-b-[1px] border-b-black ">
        <div className="w-full">
          <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
            <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#e8eaea] to-[#d7d9d9] p-4 lgl:p-8 rounded-lg shadow-shadowTwo flex flex-col gap-8 justify-center">
              <img
                className="w-full h-64 object-cover rounded-lg mb-2"
                src={ContactImg}
                alt="contactImg"
              />
              <div className="flex flex-col gap-4">
                <h3 className="text-3xl font-bold text-black">
                  Digital Transport and Logistic solution
                </h3>
                <p className="text-base text-gray-800 tracking-wide">
                  Transportation plays a vital role in society, facilitating the
                  movement of people, goods, and services from one location to
                  another.
                </p>
                <p className="text-base text-gray-600 flex items-center gap-2">
                  Phone: <span className="text-black">+2519-11-12-10-10</span>
                </p>
                <p className="text-base text-gray-600 flex items-center gap-2">
                  Email:{" "}
                  <span className="text-black">www.info8dtls@gmail.com</span>
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <h2 className="text-base font-titleFont mb-4">View More</h2>
                <div className="flex gap-4">
                  <span className="bannerIcon">
                    <FaFacebookF />
                  </span>
                  <span className="bannerIcon">
                    <FaTwitter />
                  </span>
                  <span className="bannerIcon">
                    <FaLinkedinIn />
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#f0f3f3] to-[#e6e9e9] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowTwo">
              <form className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5">
                {errMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#e1e1e1] to-[#dedcdc] shadow-shadowTwo text-center text-orange-500 text-base tracking-wide animate-bounce">
                    {errMsg}
                  </p>
                )}
                {successMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#e1e1e1] to-[#dedcdc] shadow-shadowTwo text-center text-green-500 text-base tracking-wide animate-bounce">
                    {successMsg}
                  </p>
                )}
                <div className="w-full flex flex-col lgl:flex-row gap-10">
                  <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                    <p className="text-sm text-lightText2 uppercase tracking-wide">
                      your name
                    </p>
                    <input
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      className={`${
                        errMsg === "Username is required!" &&
                        "outline-designColor"
                      } contactInput`}
                      type="text"
                    />
                  </div>
                  <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                    <p className="text-sm  uppercase tracking-wide text-lightText2">
                      phone number
                    </p>
                    <input
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      className={`${
                        errMsg === "Phone number is required!" &&
                        "outline-designColor"
                      } contactInput`}
                      type="text"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <p className="text-sm  uppercase tracking-wide text-lightText2">
                    email
                  </p>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className={`${
                      errMsg === "Please give your Email!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="email"
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <p className="text-sm  uppercase tracking-wide text-lightText2">
                    subject
                  </p>
                  <input
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    className={`${
                      errMsg === "Plese give your Subject!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="text"
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <p className="text-sm uppercase text-lightText2">message</p>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    className={`${
                      errMsg === "Message is required!" && "outline-designColor"
                    } contactTextArea`}
                    cols="30"
                    rows="7"
                  ></textarea>
                </div>
                <div className="w-full">
                  <button
                    onClick={handleSend}
                    className="w-full h-12 bg-[#fafafb] hover:bg-[#f0f3f3] rounded-lg text-lightText2 tracking-wider uppercase duration-300 hover:border-[1px] hover:border-designColor border-transparent"
                  >
                    send message
                  </button>
                </div>
                {errMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#e1e1e1] to-[#dedcdc] shadow-shadowTwo text-center text-orange-500 text-base tracking-wide animate-bounce">
                    {errMsg}
                  </p>
                )}
                {successMsg && (
                  <p className="py-3 bg-gradient-to-r from-[#e1e1e1] to-[#dedcdc] shadow-shadowTwo text-center text-green-500 text-base tracking-wide animate-bounce">
                    {successMsg}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className="my-12">
        <Flex
          position="relative"
          flexDirection="column"
          alignItems="center"
          h="50vh"
          w="100vw"
        >
          {isLoaded ? (
            <Box position="absolute" left={0} top={0} h="100%" w="100%">
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                options={{
                  zoomControl: true,
                  streetViewControl: true,
                  mapTypeControl: true,
                  fullscreenControl: false,
                }}
                onLoad={(map) => setMap(map)}
              >
                <Marker position={center} />
              </GoogleMap>
            </Box>
          ) : (
            <div>loading...</div>
          )}
        </Flex>
      </div>
    </Layout>
  );
};

export default Contact;
