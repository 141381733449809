import React, { useEffect, useRef, useState } from "react";
import {
  RiCloseFill,
  RiFacebookBoxFill,
  RiGoogleFill,
  RiLinkedinBoxFill,
  RiPhoneFill,
  RiSearch2Line,
} from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../Components/Dialog/Dialog.tsx";
import Search from "../Search.jsx";
import TopHeader from "./topHeader.js";

const TrialHeader = () => {
  //ref for the hidden sidenav
  const sidenav = useRef();
  //ref for the background of sidenav
  const second = useRef();
  //function that trigger the hidden sidenav to open up
  const triggerSideNav = () => {
    sidenav.current.classList.toggle("translate-x-[-100%]");
    second.current.classList.toggle("hidden");
    second.current.classList.add("opacity-40");
  };

  //
  const [navBackground, setNavBackground] = useState(false);
  //ref that hold value navbackground val
  const navRef = useRef();

  navRef.current = navBackground;

  // use effect
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 300;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let AllLink = [
    {
      href: "/",
      name: "Home",
    },
    {
      href: "/about",
      name: "About",
    },
    {
      href: "/contact",
      name: "Contacts",
    },
  ];

  return (
    <>
      <header
        id="name"
        className=" w-full bg-gray-200 transition-all ease duration-[2000ms] text-black z-[200] "
        style={{
          position: navBackground ? "fixed" : "relative",
          boxShadow: navBackground
            ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            : "0 0 #0000",
        }}
      >
        {navBackground ? null : <TopHeader />}
        <div className="flex h-20 items-center">
          <div className="container flex justify-around items-center mx-auto">
            <div className="flex items-center">
              <Link className="text-redButton flex flex-row" to="/">
                <div className="flex flex-row items-center justify-center">
                  <img
                    src="/logo.jpg"
                    style={{ width: "50px" }}
                    alt=""
                    className="rounded-full border"
                    srcSet=""
                  />
                  <p className="text-black font-bold text-lg px-2">DTLS</p>
                </div>
              </Link>
            </div>

            <div className="flex items-center">
              <nav aria-label="Global" className="hidden md:block ">
                <ul className="flex items-center md:gap-3 lg:gap-6 text-sm xl:ml-32">
                  {AllLink.map((each, index) => {
                    return (
                      <li key={index}>
                        <Link
                          className="text-gray-600 text-lg transition hover:text-[#0d9488] font-medium text-center"
                          to={each.href}
                        >
                          {" "}
                          {each.name}{" "}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>

            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="sm:flex items-center sm:gap-4 ">
                  <div className="flex  flex-row items-center text-gray-500 gap-8 text-xl lg:mr-4">
                    <RiGoogleFill />
                    <RiFacebookBoxFill href="https://www.facebook.com/profile.php?id=100087511914550&sk=about" />
                  </div>
                </div>
              </div>

              <button
                className="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden"
                onClick={() => {
                  triggerSideNav();
                }}
              >
                <span className="sr-only">Toggle menu</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              <Dialog className="w-auto h-full bg-gray-700">
                <DialogTrigger asChild>
                  <button className=" hidden  bg-tealButton text-center px-[20px]  lg:px-[44px] h-[100%]  py-2.5 text-sm font-medium  transition  sm:block">
                    <RiSearch2Line className="text-white text-lg" />
                  </button>
                </DialogTrigger>

                <DialogContent className=" bg-titleColor w-full w-full lg:w-7/12 md:w-6/12 py-28">
                  <Search dialog={true} />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
        <div
          ref={sidenav}
          className="h-screen w-full  top-0 left-0  transition  absolute ease-in-out duration-500  translate-x-[-100%]"
        >
          <div
            className="w-[90%] xs:w-[80%]  bg-titleColor text-white z-[300] relative h-full"
            // ref={sidenav}
            key={0}
          >
            <div className="flex justify-end p-3 text-xl h-[10%]">
              <button
                onClick={() => {
                  triggerSideNav();
                }}
                className="border-2 px-4 border-gray-500 rounded-full"
              >
                <RiCloseFill />
              </button>
            </div>
            <div className="h-[90%] flex flex-col justify-around text-white items-center space-y-3 text-sm gap-2">
              <ul className="">
                {AllLink.map((each, index) => {
                  return (
                    <NavLink
                      className="text-lg transition  font-medium  hover:bg-pink-800 "
                      to={each.href}
                      key={index}
                    >
                      <li
                        key={index}
                        className="rounded-lg px-4 py-2 w-40 text-center my-6 "
                      >
                        {" "}
                        {each.name}{" "}
                      </li>
                    </NavLink>
                  );
                })}
              </ul>
              <div className="flex flex-row items-center text-white gap-2 text-xl">
                <RiGoogleFill />
                <RiFacebookBoxFill href="https://www.facebook.com/profile.php?id=100087511914550&sk=about" />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={second}
          className=" flex-row w-screen h-screen top-0  z-[250] bg-black opacity-45  hidden  transition  absolute ease-in-out duration-500"
          onClick={() => triggerSideNav()}
        ></div>
      </header>
    </>
  );
};

export default TrialHeader;
