import React from "react";

const PopularDestination = () => {
  return (
    <div className="my-20 container mx-auto flex flex-col justify-center items-center">
      <h1 className="text-center font-normal text-4xl">Popular Destination</h1>
      <p className="my-6 w-[70%] lg:w-1/3 text-center text-gray-600">
        Suffered alteration in some form, by injected humour or good day
        randomised booth anim 8-bit hella wolf moon beard words.
      </p>
      <div className="grid w-full gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-[2000px]  px-6 md:mx-0">
        {/* <div className="flex flex-row items-center justify-center lg:justify-between flex-wrap gap-4    mx-4 md:mx-0"> */}
        {/* <div className="grid gap-9 grid-cols-fluid"> */}
        <a
          href="#"
          className="group relative block bg-black w-full h-56 xl:h-72"
        >
          <img
            alt="Developer"
            src="https://media.gettyimages.com/id/148824117/photo/fasilidas-palace-royal-enclosure.jpg?s=612x612&w=0&k=20&c=8I25JnPLCqnzJBysT_vCSnpm6JgZbcBx5exQk8Fcwqo="
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">Gonder</p>
          </div>
        </a>
        <a
          href="#"
          className="group relative block bg-black w-full  h-56 xl:h-72 "
        >
          <img
            alt="Developer"
            src="https://images.unsplash.com/flagged/photo-1572644973628-e9be84915d59?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">Lalibela</p>
          </div>
        </a>
        <a
          href="#"
          className="group relative block bg-black w-full  h-56 xl:h-72 "
        >
          <img
            alt="Developer"
            src="https://media.gettyimages.com/id/956151362/photo/a-monk-in-the-monastery-of-debre-birhan-selassie-in-gondar-gondar-ethiopia.jpg?s=612x612&w=0&k=20&c=DWZ2Le5eo6mjid1NIdCWC85jWN-tZC8PW3ytleOBgvQ="
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">
              Debre Birhan
            </p>
          </div>
        </a>
        <a
          href="#"
          className="group relative block bg-black w-full  h-56 xl:h-72 "
        >
          <img
            alt="Developer"
            src="https://www.hlimg.com/images/places2see/738X538/dessie-ethiopia-by-michot3-d5eq8lk_1563431522-8828e.jpg"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">Dessie</p>
          </div>
        </a>
        <a
          href="#"
          className="group relative block bg-black w-full  h-56 xl:h-72 "
        >
          <img
            alt="Developer"
            src="https://media.gettyimages.com/id/627478730/photo/addis-ababa-at-nightfall.jpg?s=612x612&w=gi&k=20&c=pj4t65gLEUZyBk21jIWhgwpd5CRXtDwvXnHUlF-KYZM="
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">
              Addis Ababa
            </p>
          </div>
        </a>
        <a
          href="#"
          className="group relative block bg-black w-full  h-56 xl:h-72 "
        >
          <img
            alt="Developer"
            src="https://d3e1m60ptf1oym.cloudfront.net/34172940-3625-4c46-adf0-cbf4be5010a3/Ethiopia-1408-0235_xlarge.jpg"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8 mt-36 xl:mt-48">
            {/* <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
              Developer
            </p> */}

            <p className="text-xl font-bold text-white sm:text-2xl">
              Debre Markos
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default PopularDestination;
