import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserModel from "../../Components/Modal/popup-model";
import LoginForm from "../User/userLogin";
import { addPayment } from "../../States/Action/Payment-Action/PaymentAction";

const Pay = ({ amount }) => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const [showLogin, setShowLogin] = useState(false);
  const dispatch = useDispatch();

  console.log("Payment Detail Data : ", amount);

  const { payment, success } = useSelector((state) => state.payment);
  console.log("paymentDetail callback_url: ", payment);

  const onSubmit = (data) => {
    const newData = {
      ...data,
      // amount: data,
      busId: "3",
      terminalId: 1,
      passengerId: "10",
      seatNumber: "31",
      reservationDate: "24-02-16",
    };

    dispatch(addPayment(newData));
    if (success == true) {
      window.location.replace(
        `https://checkout.chapa.co/checkout/web/payment/PL-50qPQGsrS9La`
      );
    }
  };

  return (
    <>
      {userInfo ? (
        <button
          type="submit"
          onClick={() => onSubmit(amount)}
          className="flex py-2 px-4 rounded text-primary bg-tealButton  hover:bg-redButton text-lg capitalize"
        >
          book now
        </button>
      ) : (
        <button
          onClick={() => setShowLogin(true)}
          type="submit"
          className="flex py-2 px-4 rounded text-primary bg-tealButton  hover:bg-redButton text-lg capitalize"
        >
          book now
        </button>
      )}
      <UserModel isVisible={showLogin} onClose={() => setShowLogin(false)}>
        <LoginForm />
      </UserModel>
    </>
  );
};
export default Pay;
