import React from "react";
import Layout from "../Layouts/Navigation/layout";
import Items from "../Layouts/Navigation/Items";
import RecentTrips from "../Layouts/Navigation/recentTrips";
import { Review } from "../Layouts/Navigation/Review";
import aboutImg1 from "../Assets/Images/Company-logo/aboutUsimg.jpg";
import aboutImg2 from "../Assets/Images/Company-logo/aboutusimg12.jpg";
//import Trips from "./Trips/trips";
const About = () => {
  return (
    <>
      <Layout>
        <div className="w-full h-80 bg-gray-600 flex items-center justify-center">
          <h2
            className="text-6xl md:text-8xl my-5 font-medium text-center text-white"
            style={{ fontFamily: "newone" }}
          >
            About Us
          </h2>
        </div>

        <h1 className="text-3xl text-center font-semibold text-gray-700 my-10">
          Bahir Dar Bus Station
        </h1>
        <div className=" mx-auto max-w-[900px] mb-6 text-gray-600 px-5">
          <p>
            The system provides the live location of the bus to the user and
            admins. The applications can not only fetch the bus location but
            also know estimate time taken by bus to reach its destination. The
            Location information is fetched from the cloud database which
            receives the data regarding the location from a separate application
            used by drivers/conductors on the bus. This helps in maintaining the
            uniqueness of the bus while displaying its location on the map. The
            request made by the client for the bus information will be fetched
            from the database and delivered to client through server.
          </p>
          <p className="pt-8">
            The driver/conductor will send its coordinates continuously to our
            server where data will be stored. When the user selects that
            particular Bus ID, its location will be retrieved from the server
            and shown on the map. Since the coordinates will be changing, the
            point on the map will keep on moving, hence the user can actually
            see the live location of the selected bus. Also we will use google’s
            distance matrix algorithm to show the user the approximate time
            taken by bus to reach the user. The application will be developed
            using android studio which has a very simple User Interface to use
            it. Google maps and telebirr APIs are the core component that will
            be used in it, which are very easy to use and explore maps with
            simple gestures such as pinch to zoom tap to point etc. with payment
            gateway. It will make tracking the bus with the surrounding safety
            features very easy for the user.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 ">
            <img src={aboutImg1} alt="" srcSet="" className="h-72" />
            <img src={aboutImg2} alt="" srcSet="" className="h-72" />
          </div>
        </div>

        {/* <Items /> */}
        {/* <Review /> */}
        <RecentTrips />
        {/* <Trips /> */}
      </Layout>
    </>
  );
};

export default About;
