import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { singleTracking } from "../../Action/Tracking-Action/TrackingAction";

const initialState = {
  tracking: {},
  tracking: [],
  loading: false,
  error: null,
  success: false,
};

const trackingSlice = createSlice({
  reducers: {},
  name: "tracking",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singleTracking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(singleTracking.fulfilled, (state, action) => {
        state.loading = false;
        state.tracking = action.payload;
      })
      .addCase(singleTracking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default trackingSlice.reducer;
