import React, { useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Button from "../../Components/Buttons/tealButton";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";

import emblaCarouselAutoplay from "embla-carousel-autoplay";
import Autoplay from "embla-carousel-autoplay";

export const Review = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "start" },
    [Autoplay({ stopOnInteraction: false })]
  );
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  return (
    <>
      <div
        className="overflow-hidden relative bg-[#f7fafd] "
        style={{
          flex: "0 0 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundImage:
            'url("https://media.istockphoto.com/id/1154164634/photo/white-bus-traveling-on-the-asphalt-road-around-line-of-trees-in-rural-landscape-at-sunset.jpg?s=2048x2048&w=is&k=20&c=Bu7Rgk_ja7UIzSmMCz__a2H5VgeqWh4oyEvgVerghho=")',
        }}
        ref={emblaRef}
      >
        <div className="flex   text-white">
          <div
            className=" min-w-0 w-full h-full  flex items-center justify-center "
            style={{
              flex: "0 0 100%",
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className=" flex justify-center flex-col items-center py-16 z-[10]">
              <div
                className=" w-28 h-28 border border-white rounded-full"
                style={{ fontFamily: "newone" }}
              >
                <img src="" alt="" srcSet="" />
              </div>

              <p className="my-4  px-3 md:px-0 text-center w-[80%] md:w-1/2 lg:text-lg">
                {" "}
                "The staff were friendly and helpful, making the travel
                experience pleasant."
              </p>
            </div>
          </div>
          <div
            className=" min-w-0 w-full h-full  flex items-center justify-center "
            style={{
              flex: "0 0 100%",
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className=" flex justify-center flex-col items-center py-16 z-[10]">
              <div
                className=" w-28 h-28 border border-white rounded-full"
                style={{ fontFamily: "newone" }}
              >
                <img src="" alt="" srcSet="" />
              </div>

              <p className="my-4  px-3 md:px-0 text-center w-[80%] md:w-1/2 lg:text-lg">
                {" "}
                "The staff were friendly and helpful, making the travel
                experience pleasant."
              </p>
            </div>
          </div>
          <div
            className=" min-w-0 w-full h-full  flex items-center justify-center "
            style={{
              flex: "0 0 100%",
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className=" flex justify-center flex-col items-center py-16 z-[10]">
              <div
                className=" w-28 h-28 border border-white rounded-full"
                style={{ fontFamily: "newone" }}
              >
                <img src="" alt="" srcSet="" />
              </div>

              <p className="my-4  px-3 md:px-0 text-center w-[80%] md:w-1/2 lg:text-lg">
                {" "}
                "Overall, I had a great experience and would definitely choose
                this bus service again."
              </p>
            </div>
          </div>
        </div>
        <button
          className="embla__prev absolute top-[45%] left-[10vw] py-2 md:py-4 rounded-full px-2 md:px-4 hover:opacity-100 transition-all text-black   bg-white border text-center"
          onClick={scrollPrev}
        >
          <RiArrowLeftLine className=" " />
        </button>
        <button
          className="embla__next absolute top-[45%] right-[10vw] py-2 md:py-4 rounded-full px-2 md:px-4 hover:opacity-100 transition-all text-black   bg-white border"
          onClick={scrollNext}
        >
          <RiArrowRightLine className=" " />
        </button>
      </div>
    </>
  );
};
