import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../Layouts/Navigation/Search";
import { RiCloseLine, RiDeleteBin2Line } from "react-icons/ri";
import Loader from "../../Components/Loader";
import Button from "../../Components/Buttons/redButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layouts/Navigation/layout";
import Pay from "../Payment/Pay";
import { singleAssignation } from "../../States/Action/Assignation-Action/AssignationAction";
import Loading from "../../Pages/Loading/Loading";
import BackTotop from "../BackToTop/BackToTop";
import howthesystem from "../../Assets/Images/Bus-image/howthesystem.jpg";

const SearchResult = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let from = useParams().from;
  let to = useParams().to;
  let date = useParams().date;

  const redableDate = (d) => {
    return d.toLocaleDateString("en-us", {
      weekday: "long",
      month: "short",
      day: "numeric",
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(singleAssignation(5));
  }, []);

  const assignation = useSelector((state) => state.assignation);
  // console.log("assignation bus info : ", assignation?.assignation?.bus);
  // console.log("assignation route info : ", assignation?.assignation?.route);

  const routeDate = (num, sign) => {
    let d = new Date(date);
    if (sign === "plus") {
      d.setDate(d.getDate() + (num + 1));
    } else {
      d.setDate(d.getDate() - num);
    }
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    const final = `${year}-${month}-${day}`;

    return final;
  };

  const optionalDates = [];

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://yeshewabirhan.abyssiniasoftware.com/api/filter-route?destinationStationId=${to}&sourceStationId=${from}&date=${date}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          // setLoading(false);
          return;
        }
        setData(data);
        // setLoading(false);
      })
      .catch((err) => {
        setError(err);
        // setLoading(false);
      });
  }, [from, to, date]);

  console.log("search bussss1 : ", data);

  console.log("search bussss2 : ", data?.data?.cost);

  const navigate = useNavigate();
  //called when detail button is clicked
  const configureSeat = (trip) => {
    navigate("/singleTickets/" + trip.id);
  };

  return (
    <Layout>
      <div className="bg-gray-200">
        {/* <TrialHeader /> */}
        <div className="bg-titleColor py-8">
          <Search />
        </div>
        <div className="container mx-auto py-3 flex items-center justify-center">
          <div className="w-full h-auto bg-white p-2 rounded-sm flex flex-row gap-2 overflow-x-auto overflow-y-hidden whitespace-nowrap">
            {[5, 4, 3, 2, 1].map((each, index) => {
              return (
                <Link to={`/search/${from}/${to}/${routeDate(each, "minus")}`}>
                  <div className="  border-2 flex items-center justify-center flex-col py-3 px-3 border-green-100">
                    <p className="text-center ">
                      {(() => {
                        let day = new Date(date);
                        day.setDate(day.getDate() - each);
                        return redableDate(day);
                      })()}
                    </p>
                  </div>
                </Link>
              );
            })}
            <div className="  border-2 flex items-center justify-center flex-col py-3 px-3 border-green-600">
              <p className="text-center ">
                {(() => {
                  let day = new Date(date);
                  day.setDate(day.getDate());
                  return redableDate(day);
                })()}
              </p>
            </div>
            {[1, 2, 3, 4, 5].map((each, index) => {
              return (
                <Link to={`/search/${from}/${to}/${routeDate(index, "plus")}`}>
                  <div className="  border-2 flex items-center justify-center flex-col py-3 px-3 border-green-100">
                    <p className="text-center ">
                      {(() => {
                        let day = new Date(date);

                        day.setDate(day.getDate() + each);

                        return redableDate(day);
                      })()}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="overflow-x-auto container mx-auto">
          <div class="grid grid-cols-1 gap-4 transition-[grid-template-columns] lg:grid-cols-[1fr_260px] lg:gap-8">
            <div class="">
              <div className="col-span-3 bg-titleColor flex justify-between p-3 text-xl">
                <div className="text-white uppercase">
                  route : bahir dar - adiss abeba
                </div>
                <div className="md:flex hidden  text-white uppercase">
                  monday
                </div>
                <div className="md:flex hidden text-white uppercase">
                  DATE : 26 february 2024
                </div>
              </div>
            </div>
            <div className="">
              <div class="container flex justify-center mx-auto">
                <div class="flex flex-row mx-auto gap-6">
                  <button
                    type="button"
                    class="bg-gray-400 text-white rounded-l-md border-r border-gray-100 py-4 hover:bg-tealButton hover:text-white px-3"
                  >
                    <div class="flex flex-row align-middle">
                      <svg
                        className="w-5 mr-2 text-black bg-white rounded-full m-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=""
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>

                      <p class="ml-2">Previous</p>
                    </div>
                  </button>
                  <button
                    type="button"
                    class="bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-tealButton hover:text-white px-3"
                  >
                    <div class="flex flex-row align-middle">
                      <span class="mr-2">Next</span>
                      <svg
                        className="w-5 mr-2 text-black bg-white rounded-full m-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto container mx-auto py-4 flex flex-wrap">
          <div className="grow-0 shrink-0 basis-auto w-full md:w-3/12 xl:w-3/12">
            <div className="m-2 rounded-md bg-gradient-to-r from-[#ffffff] to-[#ffffff]">
              <div className="flex justify-between px-6 py-6">
                <h1 className="text-2xl text-black-600/100 font-medium">
                  Filter
                </h1>
                <p>Reset All</p>
              </div>

              <span class="flex items-center">
                <span class="h-px flex-1 bg-gray-300"></span>
              </span>
              <div className="px-6 py-2">
                <h1 className="text-lg text-black-600/100 font-medium">
                  Vehicle Type
                </h1>

                <div>
                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-4 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> Level 1 </p>
                    </div>
                  </label>

                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-4 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                        />
                      </svg>

                      <p class="font-sm text-gray-400"> Level 2 </p>
                    </div>
                  </label>

                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-4 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> Level 3 </p>
                    </div>
                  </label>
                </div>
              </div>
              <span class="flex items-center">
                <span class="h-px flex-1 bg-gray-300"></span>
              </span>
              <div className="px-6 py-2">
                <h1 className="text-lg text-black-600/100 font-medium">
                  Routes
                </h1>
                <div>
                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center px-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex px-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-4 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> Bahir Dar - Gonder</p>
                    </div>
                  </label>

                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-4 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> Markos - Bahir dar </p>
                    </div>
                  </label>
                </div>
              </div>

              <span class="flex items-center">
                <span class="h-px flex-1 bg-gray-300"></span>
              </span>
              <div className="px-6 py-2">
                <h1 className="text-lg text-black-600/100 font-medium">
                  Schedules
                </h1>

                <div class="space-y-1">
                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-3 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> 12:00 am - 6:00 pm </p>
                    </div>
                  </label>

                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-3 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <p class="font-sm text-gray-400"> 7:00 pm - 6:00 am </p>
                    </div>
                  </label>

                  <label class="flex cursor-pointer items-start gap-2 p-1">
                    <div class="flex items-center p-1">
                      &#8203;
                      <input
                        type="checkbox"
                        class="size-4 rounded border-gray-300"
                        id="Option1"
                      />
                    </div>
                    <div className="rounded-md border border-gray-200 transition bg-gray-100 flex p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        class="w-3 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>

                      <p class="font-sm text-gray-400"> 9:30 am - 1:00 pm </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="grow-0 shrink-0 basis-auto w-full md:w-9/12 xl:w-9/12">
            {data &&
              !data?.error &&
              !data?.errors &&
              data.map((trip, index) => {
                return (
                  <div className="py-2">
                    <div class="grid grid-cols-1 gap-2 lg:grid-cols-5 lg:gap-4 border-2 border-gray-300 rounded-md bg-white md:bg-gradient-to-r md:from-[#ffffff] md:to-[#d7d9d9] ">
                      <div class=" lg:col-span-2">
                        <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mx-2 md:justify-between flex">
                          <div className="py-1 md:py-4">
                            <h1 className="text-xl uppercase text-[#32817A] font-bold flex items-center justify-start ml-6 md:ml-0">
                              {trip?.bus?.model || "D4D Express"}
                            </h1>
                            <p className="text-lg flex items-center justify-start ml-6 md:ml-0">
                              <span className="font-bold">21md - </span>
                              single-deck
                            </p>
                            <p className="text-xl flex items-center justify-start ml-6 md:ml-0">
                              talga : <span className="font-bold"> 98579</span>
                            </p>
                          </div>
                          <div className="py-1 md:py-6">
                            <h1 className="text-md uppercase flex items-center justify-start md:justify-end ml-6 md:ml-0">
                              departure time
                            </h1>
                            <p className="text-xl uppercase text-[#32817A] flex items-center justify-start md:justify-end pr-4 font-bold ml-6 md:ml-0">
                              3:45 AM
                            </p>
                          </div>
                        </div>
                      </div>
                      <span class="flex md:hidden lg:hidden items-center">
                        <span class="h-px flex-1 bg-gray-300"></span>
                      </span>
                      <div class=" lg:col-span-2 md:border-x-2 md:border-gray-300 md:border-dashed">
                        <div className="grid grid-cols-2 gap-4 h-full">
                          <div className="w-full h-full flex justify-center md:border-dashed md:border-r-2 md:border-gray-300">
                            <div className="py-1 md:py-8">
                              <h1 className="uppercase text-md">
                                arrival time
                              </h1>
                              <p className="text-xl uppercase text-[#32817A] font-bold flex justify-center">
                                {trip.estimatedTime} PM
                              </p>
                            </div>
                          </div>
                          <div className="w-full flex justify-center">
                            <div className="py-1 md:py-8">
                              <h1 className="uppercase text-md ">
                                available seats
                              </h1>
                              <p className="text-xl uppercase text-[#32817A] font-bold flex justify-center">
                                42
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="lg:col-span-1">
                        <div className="grid m-2">
                          <div className="w-full flex md:justify-center justify-end py-1 md:py-2 px-4">
                            <h1 className="text-md font-bold">
                              1 ~ {trip?.cost}
                            </h1>
                          </div>
                          <div className="w-full flex md:justify-center justify-end">
                            <button
                              className="flex py-2 px-4 rounded text-primary bg-tealButton hover:bg-redButton text-md capitalize"
                              onClick={() => configureSeat(trip)}
                            >
                              view seats
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {data && !data?.error && !data?.errors && data?.length === 0 && (
              <div className="w-full h-52 flex flex-rows items-center justify-center">
                <span>No Bus Available</span>
              </div>
            )}
            {(error && !data) ||
              data?.error ||
              (data?.errors && (
                // <div className="flex justify-center items-center flex-col w-full py-36 border">
                //   <h1 className="text-3xl text-black">Error loading page</h1>
                //   <Button className={" text-center my-3"}>Reload</Button>
                // </div>
                <Loading />
              ))}
            {loading && !data && !error && <Loader />}
          </div>
        </div>
        <div className="w-full">
          <div className="border min-w-0  flex-2  lg:flex-3  h-full  flex items-center justify-center mx-2 ">
            <article className="rounded-lg  transition w-full md:flex ">
              <img alt="" src={howthesystem} className="w-full object-cover" />

              <div className="bg-white  sm:py-2 item-center mt-4">
                <p className="text-gray-600">
                  Easy Steps on How EplusApp Tracks the Bus Live
                </p>

                <h3 className=" text-xl text-gray-900 pr-2">
                  Know the live location of your bus, departure and arrival
                  timings. Plan wisely and act accordingly by tracking live
                  movements of your bus at EplusApp website or mobile app. Enter
                  your EplusApp Booking ID and your email ID or your phone
                  number on the EplusApp digital tracking search bar. Click on
                  the “Track Bus” button to view the live location of your bus
                  through Google maps on the page. You will also receive an
                  email or SMS with the tracking link for quick access to
                  tracking your bus.
                </h3>
              </div>
            </article>{" "}
          </div>
        </div>
      </div>
      <BackTotop />
    </Layout>
  );
};

export default SearchResult;
