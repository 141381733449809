import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import Autoplay from "embla-carousel-autoplay";
import { Link, useParams } from "react-router-dom";
import { DialogClose } from "../../Components/Dialog/Dialog.tsx";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../States/Reducer/Bus-Reducer/terminal.js";
import Datepicker from "react-tailwindcss-datepicker";
import bus1 from "../../Assets/Images/Bus-image/d4dAyirufff.png";
import bus2 from "../../Assets/Images/Bus-image/bus13svg.png";
import bus3 from "../../Assets/Images/Bus-image/bus12svg.png";
import bus4 from "../../Assets/Images/Bus-image/costersvg.png";

export const Hero = ({ dialog = false, f = null, t = null, d = null }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ stopOnInteraction: false }),
  ]);
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.buses);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch, useParams().data]);

  const [from, setFrom] = useState(useParams().from || null);
  const [to, setTo] = useState(useParams().to || null);
  const [date, setDate] = useState({
    startDate: useParams().date,
    endDate: useParams().date,
  });

  const removeDuplicate = (arr, key) => {
    return [...new Map(arr.map((x) => [key(x), x])).values()];
  };

  //starting and ending terminals
  let start = [];
  let end = [];

  //receive all terminals and remove the duplicate one and return start and end terminal in array
  const pureTerminal = (arr) => {
    arr.forEach(function (each) {
      start.push({
        name: each?.sourceStation?.stationName,
        id: each?.sourceStation?.stationId,
      });
      end.push({
        name: each?.destinationStation?.stationName,
        id: each?.destinationStation?.stationId,
      });
    });
    start = removeDuplicate(start, (it) => it.id);
    end = removeDuplicate(end, (it) => it.id);

    return [start, end];
  };

  const [val, setVal] = useState({
    startDate: null,
    endDate: null,
  });

  return (
    <div className="w-full">
      {/* 
      <div className=" relative overflow-hidden group" ref={emblaRef}>
        <div className="flex h-[90vh] 2xl:h-[70vh] max-h-[900px] hidden md:flex">
          <div
            className=" min-w-0 w-full h-full  flex items-center justify-end pr-20"
            style={{
              flex: "0 0 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundImage:
                'url("https://media.gettyimages.com/id/148824117/photo/fasilidas-palace-royal-enclosure.jpg?s=612x612&w=0&k=20&c=8I25JnPLCqnzJBysT_vCSnpm6JgZbcBx5exQk8Fcwqo=")',
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className="text-white flex justify-center flex-col items-center z-[10]">
              <h2
                className="text-6xl md:text-8xl my-5 font-medium text-center"
                style={{ fontFamily: "newone" }}
              >
                Gonder
              </h2>

              <p className="my-2 mb-8 px-3 md:px-0 text-center lg:text-lg xl:text-2xl">
                {" "}
                Gonder city: Historic Ethiopian city known for its royal
                castles.
              </p>
            </div>
          </div>
          <div
            className=" min-w-0 w-full h-full  flex items-center justify-end pr-20"
            style={{
              flex: "0 0 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundImage:
                'url("https://images.unsplash.com/flagged/photo-1572644973628-e9be84915d59?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className="text-white flex justify-center flex-col items-center z-[10]">
              <h2
                className="text-6xl md:text-8xl my-5 font-medium text-center"
                style={{ fontFamily: "newone" }}
              >
                Lalibela
              </h2>

              <p className="my-2 mb-8 px-3 md:px-0 text-center lg:text-lg xl:text-2xl">
                {" "}
                Lalibela: Ethiopian city known for rock-hewn churches and
                religious history.
              </p>
            </div>
          </div>
          <div
            className=" min-w-0 w-full h-full flex  items-center justify-end "
            style={{
              flex: "0 0 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundImage:
                'url("https://media.gettyimages.com/id/627478730/photo/addis-ababa-at-nightfall.jpg?s=612x612&w=gi&k=20&c=pj4t65gLEUZyBk21jIWhgwpd5CRXtDwvXnHUlF-KYZM=")',
            }}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-0"></div>
            <div className="text-white flex justify-center flex-col items-center z-[10] pr-20">
              <h2
                className="text-6xl md:text-8xl my-5 font-medium text-center"
                style={{ fontFamily: "newone" }}
              >
                Addis Ababa
              </h2>

              <p className="my-2 mb-8 px-3 md:px-0 text-center lg:text-lg xl:text-2xl">
                {" "}
                Addis Abeba : Located in Ethiopia, Home of African Union
              </p>
            </div>
          </div>
        </div>
        <button
          className="embla__prev absolute top-[45%] left-0 py-2 md:py-7 px-2 md:px-4 hover:opacity-100 transition-all text-black  opacity-35 bg-white text-center"
          onClick={scrollPrev}
        >
          <RiArrowLeftLine className=" " />
        </button>
        <button
          className="embla__next absolute top-[45%] right-0 py-2 md:py-7 px-2 md:px-4 hover:opacity-100 transition-all text-black  opacity-35 bg-white"
          onClick={scrollNext}
        >
          <RiArrowRightLine className=" " />
        </button>
      </div>

      <section className="w-full lg:w-3/12 md:w-2/12 md:absolute relative bottom-0 left-0 md:left-[10%] top-2 md:top-[25%]">
        <div className="w-full bg-titleColor lg:pt-5">
          <h1 className="text-xl font-semibold min-w-36 px-2 text-center text-white mb-3">
            Where you want to go?
          </h1>
          <div className="w-full mx-auto gap-2 items-center text-white justify-between px-2 md:px-8 lg:px-10 space-y-6">
            <div>
              From
              <select
                id="countries"
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                defaultValue={useParams().from || "DEFAULT"}
                className="w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
              >
                <option value="DEFAULT" disabled>
                  {loading && !data && !error && "loading ..."}
                  {((error && !data) || data?.error) && "can't load countries"}
                  {data && !data?.error && "Choose a country"}
                </option>

                {data &&
                  !data?.error &&
                  pureTerminal(data)[0].map((each, index) => {
                    return (
                      <option value={each.id} key={index} className="p-2">
                        {each.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div>
              To
              <select
                id="countries"
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                defaultValue={useParams().to || "DEFAULT"}
                className="w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
              >
                <option value="DEFAULT" disabled>
                  {loading && !data && !error && "loading ..."}
                  {((error && !data) || data?.error) && "can't load countries"}
                  {data && !data?.error && "Choose a country"}
                </option>
                {JSON.stringify(data)}
                {data &&
                  !data.error &&
                  pureTerminal(data)[1].map((each, index) => {
                    return (
                      <option value={each.id} key={index} className="p-2">
                        {each.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div>
              <p>Date</p>
              <Datepicker
                classNames={"w-full"}
                asSingle={true}
                minDate={date}
                value={date}
                onChange={(newVal) => {
                  setDate(newVal);
                }}
              />
            </div>

            <div class="w-full text-right text-gray-500 item-center pb-8 pt-2">
              <Link
                className={
                  "w-1/2 focus:outline-none mr-4 rounded text-primary bg-tealButton hover:bg-redButton text-lg capitalize px-8 py-4"
                }
                to={`/search/${from}/${to}/${date.startDate}`}
              >
                Search
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <div className="w-full h-screen bg-banner bg-no-repeat bg-center bg-cover ">
        <div className="text-white flex justify-end flex-col items-end z-10 mr-12 flex flex-wrap">
          <h2
            className="text-6xl md:text-8xl my-4 font-bold text-center mt-0 md:mt-28 text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400"
            style={{ fontFamily: "newone" }}
          >
            Bahir Dar
          </h2>
          <p className=" md:mb-8 py-2 px-3 md:px-0 lg:text-lg xl:text-2xl font-bold opacity-100 md:mr-16">
            {" "}
            Digital Transport and Logistic System
          </p>
          <a
            class="group relative inline-flex items-center overflow-hidden rounded bg-[#0d9488] px-8 py-3 text-white focus:outline-none focus:ring active:bg-[#115e59] uppercase mr-16"
            href="/search/1/1/2024-01-28"
          >
            <span class="absolute -end-full transition-all group-hover:end-4">
              <svg
                class="size-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
            <span class="text-sm font-medium transition-all group-hover:me-4">
              {" "}
              get bus ticket now
            </span>
          </a>
        </div>

        <section className="w-full lg:w-3/12 md:w-2/12 absolute bottom-0 left-0 md:left-[10%] md:top-[50%] top-[40%] lg:top-[55%] z-[10] md:animate-trans-topp">
          <div className="w-full bg-titleColor lg:pt-5">
            <h1 className="text-xl font-semibold min-w-36 px-2 text-center text-white mb-3">
              Where you want to go?
            </h1>
            <div className="w-full mx-auto gap-2 items-center text-white justify-between px-2 md:px-8 lg:px-10 space-y-6">
              <div>
                From
                <select
                  id="countries"
                  onChange={(e) => {
                    setFrom(e.target.value);
                  }}
                  defaultValue={useParams().from || "DEFAULT"}
                  className="w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
                >
                  <option value="DEFAULT" disabled>
                    {loading && !data && !error && "loading ..."}
                    {((error && !data) || data?.error) &&
                      "can't load countries"}
                    {data && !data?.error && "Choose a country"}
                  </option>

                  {data &&
                    !data?.error &&
                    pureTerminal(data)[0].map((each, index) => {
                      return (
                        <option value={each.id} key={index} className="p-2">
                          {each.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                To
                <select
                  id="countries"
                  onChange={(e) => {
                    setTo(e.target.value);
                  }}
                  defaultValue={useParams().to || "DEFAULT"}
                  className="w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
                >
                  <option value="DEFAULT" disabled>
                    {loading && !data && !error && "loading ..."}
                    {((error && !data) || data?.error) &&
                      "can't load countries"}
                    {data && !data?.error && "Choose a country"}
                  </option>
                  {JSON.stringify(data)}
                  {data &&
                    !data.error &&
                    pureTerminal(data)[1].map((each, index) => {
                      return (
                        <option value={each.id} key={index} className="p-2">
                          {each.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div>
                <p>Date</p>
                <Datepicker
                  classNames={"w-full"}
                  asSingle={true}
                  minDate={date}
                  value={date}
                  onChange={(newVal) => {
                    setDate(newVal);
                  }}
                />
              </div>

              <div class="w-full text-right text-gray-500 item-center pb-8 pt-2">
                <Link
                  className={
                    "w-1/2 focus:outline-none mr-4 rounded text-primary bg-tealButton hover:bg-redButton text-lg capitalize px-8 py-4"
                  }
                  to={`/search/${from}/${to}/${date.startDate}`}
                >
                  Search
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="m-auto">
          <div className="bg-gray-100 w-full">
            <img
              className="absolute z-10 left-[-50%] md:left-[20%] lg:left-[50%] top-[85%] animate-trans-left"
              src={bus1}
            />
            {/* <img
              className="absolute z-10 right-[0%] md:right-[10%] md:top-[50%] animate-trans-right"
              src={bus2}
            /> */}
            <img
              className="absolute z-10 left-[-1%] top-[75%] animate-trans-front hidden md:flex"
              src={bus3}
            />
            {/* <img
              className="absolute z-10 left-[0%] md:left-[45%] top-[50%] animate-trans-front2"
              src={bus4}
            /> */}
          </div>
        </section>
      </div>
    </div>
  );
};
