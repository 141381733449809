// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../Components/Toasts/toasts";
//login users
export const loginUser = createAsyncThunk(
  "user",
  async (authData, thunkAPI) => {
    try {
      // base url
      let baseUrl = "https://yeshewabirhan.abyssiniasoftware.com/api/login";

      // response
      let response = await axios.post(baseUrl, authData);
      console.log(response);
      // condition
      if (response.status === 200) {
        // handleSuccess(response.data.message);
        // put the user info into local storage
        localStorage.setItem("userInfo", JSON.stringify(response.data));

        return response.data;
      } else {
        handleError(response.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e.response);

      handleError(e.response.data.message);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
