import React, { useEffect, useState } from "react";
import Input from "../../Components/Text-Input";
import Button from "../../Components/Buttons/redButton";
import { RiArrowDropRightLine, RiArrowRightDoubleLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { DialogClose } from "../../Components/Dialog/Dialog.tsx";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../States/Reducer/Bus-Reducer/terminal.js";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-select";

const Search = ({ dialog = false, f = null, t = null, d = null }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.buses);

  console.log("search bus :", data);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch, useParams().data]);

  const [from, setFrom] = useState(useParams().from || null);
  const [to, setTo] = useState(useParams().to || null);
  const [date, setDate] = useState({
    startDate: useParams().date,
    endDate: useParams().date,
  });

  const removeDuplicate = (arr, key) => {
    return [...new Map(arr.map((x) => [key(x), x])).values()];
  };

  //starting and ending terminals
  let start = [];
  let end = [];

  //receive all terminals and remove the duplicate one and return start and end terminal in array
  const pureTerminal = (arr) => {
    arr.forEach(function (each) {
      start.push({
        name: each?.sourceStation?.stationName,
        id: each?.sourceStation?.stationId,
      });
      end.push({
        name: each?.destinationStation?.stationName,
        id: each?.destinationStation?.stationId,
      });
    });
    start = removeDuplicate(start, (it) => it.id);
    end = removeDuplicate(end, (it) => it.id);

    return [start, end];
  };
  const [val, setVal] = useState({
    startDate: null,
    endDate: null,
  });

  return (
    <>
      <div className="w-full bg-titleColor ">
        <h1 className="text-xl font-semibold min-w-36 px-2 text-center text-white mb-3">
          Where you want to go?
        </h1>
        {/* <div className="w-full mx-auto gap-2 items-center text-white justify-between px-2 md:px-8 lg:px-10 space-y-6"> */}
        <div className=" w-full mx-auto gap-4 md:flex md:flex-col md:lg:flex-row items-center text-white justify-between px-7 md:space-y-0 space-y-6">
          <div>
            From
            <select
              id="countries"
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              defaultValue={useParams().from || "DEFAULT"}
              className="w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
            >
              <option value="DEFAULT" disabled>
                {loading && !data && !error && "loading ..."}
                {((error && !data) || data?.error) && "can't load countries"}
                {data && !data?.error && "Choose a country"}
              </option>

              {data &&
                !data?.error &&
                pureTerminal(data)[0].map((each, index) => {
                  return (
                    <option value={each.id} key={index} className="p-2">
                      {each.name}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* <Select
          className="w-full text-black"
          placeholder="From"
          options={[
            { label: "first", value: "first" },
            { label: "second", value: "second" },
          ]}
          // loadingMessage={"fetching date"}
          loading={true}
          noOptionsMessage={() => "no option available"}
        /> */}
          <div>
            To
            <select
              id="countries"
              onChange={(e) => {
                setTo(e.target.value);
              }}
              defaultValue={useParams().to || "DEFAULT"}
              className="md:block w-full rounded-md border-0 py-1.5 px-7  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm  bg-titleColor text-white border-gray-900"
            >
              <option value="DEFAULT" disabled>
                {loading && !data && !error && "loading ..."}
                {((error && !data) || data?.error) && "can't load countries"}
                {data && !data?.error && "Choose a country"}
              </option>
              {JSON.stringify(data)}
              {data &&
                !data.error &&
                pureTerminal(data)[1].map((each, index) => {
                  return (
                    <option value={each.id} key={index} className="p-2">
                      {each.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div>
            <p>Date</p>
            <Datepicker
              classNames={"w-full"}
              asSingle={true}
              minDate={date}
              value={date}
              onChange={(newVal) => {
                setDate(newVal);
              }}
            />
          </div>
          {/* {loading ? "true" : "false"} */}
          {/* {JSON.stringify(error)} */}
          {/* {JSON.stringify(data)} */}
          <div className="pt-0 md:pt-6">
            {dialog ? (
              <Link to={`/search/${from}/${to}/${date.startDate}`}>
                <DialogClose
                  className={
                    " flex py-2 px-4 rounded text-primary bg-tealButton hover:bg-redButton text-lg capitalize "
                  }
                >
                  Search
                </DialogClose>
              </Link>
            ) : (
              <Link
                className={
                  "  flex py-2 px-4 rounded text-primary bg-tealButton hover:bg-redButton text-lg capitalize mt-3 lg:mt-0"
                }
                to={`/search/${from}/${to}/${date.startDate}`}
              >
                Search
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
