import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../Layouts/Navigation/Search";
import { RiCloseLine, RiDeleteBin2Line } from "react-icons/ri";
import Loader from "../../Components/Loader";
import { Link, useParams } from "react-router-dom";
import Layout from "../../Layouts/Navigation/layout";
import HomeImg from "../../Assets/Images/Company-logo/eplusapp_home.jpg";
import Pay from "../Payment/Pay";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { singleTracking } from "../../States/Action/Tracking-Action/TrackingAction";
import seats from "../../Assets/Images/Bus-image/icons8-car-seat-64.png";
import driverIcon from "../../Assets/Images/Bus-image/icons8-toyota-100.png";
import Timer from "../Timer/Timer";
import BackTotop from "../BackToTop/BackToTop";
import { singleTicketing } from "../../States/Action/Ticketing-Action/TicketingAction";
import busTracking from "../../Assets/Images/Bus-image/trackingBus.jpg";

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";

const Ticketing = () => {
  const { id } = useParams();
  // ,map functionality
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBuAfQmrYyXSl_kZXhmWq54sFZrCIDKXgo",
    libraries: ["places"],
  });
  const [from, setFrom] = useState();
  const [to, setTo] = useState({
    lat: 8.9887252,
    lng: 38.7708718,
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [Msg, setMsg] = useState(null);
  const start = useRef();
  const destiantion = useRef();

  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      // origin: start.current?.value,
      // destination: destiantion?.current.value,
      origin: from,
      destination: to,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  useEffect(() => {
    (async () => {
      setMsg("Loading GPS...");
      if (navigator.geolocation) {
        let data = await navigator.geolocation.getCurrentPosition(
          async (postion) => {
            setTimeout(() => {
              setFrom({
                lat: postion.coords.latitude,
                lng: postion.coords.longitude,
                zoom: 18,
              });
              setMsg("");
            }, 1000);

            return {
              lat: postion.coords.latitude,
              lng: postion.coords.longitude,
            };
          }
        );
      } else alert("Please Allow your Location !");
    })();
  }, []);

  const dispatch = useDispatch();
  const phone = "940637672";

  useEffect(() => {
    dispatch(singleTracking(phone));
  }, []);

  useEffect(() => {
    dispatch(singleTicketing(id));
  }, []);

  const ticketing = useSelector((state) => state.ticketing);

  const tracking = useSelector((state) => state.tracking);

  console.log("single ticketing data :", ticketing);

  //total number of selected seats
  const [totalseat, settotalseat] = useState(0);
  //takes the number of total seats in the bus
  const [total, setTotal] = useState(32);

  //initial array for seat variable
  let newseat = [];
  for (let i = 0; i < total; i++) {
    newseat.push({
      index: i,
      available: true,
      status: false,
    });
  }

  const adjustSeat = (num) => {
    setTotal(num);
    newseat = [];
    for (let i = 0; i < num; i++) {
      newseat.push({
        index: i,
        available: true,
        status: false,
      });
    }
    setSeat(newseat);
  };

  //all bus seats
  const [seat, setSeat] = useState(newseat);
  //state for counting the total seat
  const [price, setPrice] = useState(180);

  //called when detail button is clicked
  const configureSeat = (trip) => {
    let matches = trip.cost.match(/(\d+)/);
    setPrice(parseInt(matches[0]));
    adjustSeat(12);
    // setShow(true);
    setSeat(newseat);
    settotalseat(0);
  };

  const grids = () => {
    let a = 3;
    if (total > 15) {
      a = 3;
    }
    if (total >= 25) {
      a = 4;
    }
    if (total >= 35) {
      a = 5;
    }
    let b = total;

    return (
      <>
        <div
          className="relative overflow-hidden bg-no-repeat bg-cover rounded-lg"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          <img
            className="ml-8 w-24 transition cursor-pointer duration-700 rounded-lg"
            src={driverIcon}
            alt="img not found"
          />
        </div>
        <div
          style={{
            display: "grid",
            gap: "0.5rem",
            justifyContent: "center",
            gridTemplateColumns: ` repeat(${a}, minmax(0, 1fr))`,
          }}
          className="w-auto pt-1"
        >
          {[...Array(b)].map((each, index) => {
            return (
              <>
                <button
                  key={index + 1}
                  className={
                    a == 3
                      ? (index + 1) % 3 === 0
                        ? ""
                        : "relative right-12"
                      : (index + 1) % 2 === 0
                      ? ""
                      : "ml-8"
                  }
                  onClick={() => {
                    setSeat((prev) => {
                      if (prev[index].status === false) {
                        settotalseat((prev) => prev + 1);
                      } else {
                        settotalseat((prev) => prev - 1);
                      }
                      prev[index].status = !prev[index].status;
                      return [...prev];
                    });
                  }}
                  disabled={!seat[index].available}
                >
                  <div
                    className={
                      "w-9 h-9 md:w-16 md:h-16 border relative" +
                      (seat[index].status === true
                        ? "border-2 border-black opacity-30"
                        : "")
                    }
                  >
                    <div
                      className={
                        !seat[index].available
                          ? "absolute w-full h-full opacity-50 bg-gray-700"
                          : ""
                      }
                    ></div>
                    <img
                      // src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/64/external-seat-equipments-icongeek26-outline-colour-icongeek26.png"
                      src={seats}
                      alt="external-seat-equipments-icongeek26-outline-colour-icongeek26"
                      className="w-9 h-9 md:w-16 md:h-16"
                    />
                  </div>
                </button>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className="bg-gray-200 mx-auto">
        <div className="relative overflow-hidden bg-no-repeat bg-contain  max-h-40">
          <img
            className="w-full h-screen object-cover"
            src={HomeImg}
            alt="eplus img"
          />
          <div
            className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
          >
            <div className="w-full xl:w-10/12 lg:w-10/12 md:w-11/12 mx-auto xl:mt-20 md:mt-12 mt-10">
              <div className="flex  justify-center items-center h-full">
                <div className="text-center text-white">
                  <h1
                    className="text-4xl md:text-4xl xl:text-5xl text-primary font-bold font-display tracking-tight leading-tight capitalize"
                    style={{ fontFamily: "newone" }}
                  >
                    {ticketing?.ticketing?.bus?.model || "d4d express "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-10/12 lg:w-10/12 md:w-11/12 mx-auto flex flex-wrap gap-4 justify-center py-8">
          <div className="grow-0 shrink-0 basis-auto w-full md:w-6/12 xl:w-6/12">
            <div className="border-2 border-gray-300">
              <div className="flex justify-center px-2 py-4">
                <div>{grids()}</div>
              </div>
            </div>
            <div className="py-8">
              <div className="md:flex justify-center px-6 gap-8">
                <div className="flex gap-2 my-2">
                  <button class="group relative inline-block overflow-hidden border border-[#e879f9] px-4  focus:outline-none focus:ring">
                    <span class="absolute inset-y-0 right-0 w-[12px] bg-[#e879f9] transition-all group-hover:w-full group-active:bg-[#e879f9]"></span>
                    <span class="relative text-sm  font-medium text-[#e879f9] transition-colors group-hover:text-white">
                      09
                    </span>
                  </button>
                  <p>Booked by Female</p>
                </div>
                <div className="flex gap-2 my-2">
                  <button class="group relative inline-block overflow-hidden border border-[#1ec6b6] px-4  focus:outline-none focus:ring">
                    <span class="absolute inset-y-0 right-0 w-[12px] bg-[#1ec6b6] transition-all group-hover:w-full group-active:bg-[#1ec6b6]"></span>
                    <span class="relative text-sm  font-medium text-[#1ec6b6] transition-colors group-hover:text-white">
                      04
                    </span>
                  </button>
                  <p>selected by You</p>
                </div>
              </div>
              <div className="md:flex justify-center px-6 gap-8">
                <div className="flex gap-2 my-2">
                  <button class="group relative inline-block overflow-hidden border border-[#59912A] px-4  focus:outline-none focus:ring">
                    <span class="absolute inset-y-0 right-0 w-[12px] bg-[#59912A] transition-all group-hover:w-full group-active:bg-[#59912A]"></span>
                    <span class="relative text-sm  font-medium text-[#59912A] transition-colors group-hover:text-white">
                      03
                    </span>
                  </button>
                  <p>Booked by Males</p>
                </div>
                <div className="flex gap-2 my-2">
                  <button class="group relative inline-block overflow-hidden border border-indigo-600 px-4  focus:outline-none focus:ring">
                    <span class="absolute inset-y-0 right-0 w-[12px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"></span>
                    <span class="relative text-sm  font-medium text-indigo-600 transition-colors group-hover:text-white">
                      32
                    </span>
                  </button>
                  <p>available seats</p>
                </div>
              </div>
            </div>
          </div>

          <div className="grow-0 shrink-0 basis-auto w-full md:w-4/12 xl:w-4/12">
            <div className="mb-2">
              <div className="flex justify-between px-6">
                <h1 className="text-lg text-black-600/100">
                  <strong className="text-[#32817A]">Side No</strong> -{" "}
                  {ticketing?.ticketing?.bus?.sideNo || "A830"}
                </h1>
              </div>
              <div className="flex justify-between px-6">
                <h1 className="text-lg text-black-600/100">
                  <strong className="text-[#32817A]">Talga</strong> -{" "}
                  {ticketing?.ticketing?.bus?.talga || "90830 A.M"}
                </h1>
                <p>02/22/2024</p>
              </div>
            </div>
            <div className="border-2 border-gray-300">
              <div className="py-2">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 lg:px-6">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-center">
                        Seat NO
                      </th>
                      <th scope="col" className="px-6 py-3 text-center">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3 text-center">
                        Birr
                      </th>
                      <th scope="col" className="px-6 py-3 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {seat.map((each, index) => {
                      if (each.status) {
                        // settotalseat((prev) => prev + 1);
                        return (
                          <tr className="bg-gray-200 border-b " key={index}>
                            <th
                              scope="row"
                              className="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap "
                            >
                              {each.index + 1}
                            </th>
                            <td className="px-6 py-4 text-center">Adult</td>
                            <td className="px-6 py-4 text-center">{price}</td>
                            <td className="px-6 py-4 flex justify-center">
                              <button
                                className="p-3 border border-black"
                                onClick={() => {
                                  setSeat((prev) => {
                                    if (prev[index].status === false) {
                                      settotalseat((prev) => prev + 1);
                                    } else {
                                      settotalseat((prev) => prev - 1);
                                    }
                                    prev[index].status = !prev[index].status;

                                    return [...prev];
                                  });
                                }}
                              >
                                <RiDeleteBin2Line />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>

                <h1 className="font-bold text-lg">
                  Total price - {totalseat * price}
                </h1>
                <div className="m-2">
                  <Pay amount={totalseat * price} />
                </div>
              </div>
            </div>
            <div>
              <Timer countDate="2024-02-27T00:00:00.000Z" />
            </div>
          </div>
        </div>

        <div className="mb-12">
          <Flex
            position="relative"
            flexDirection="column"
            alignItems="center"
            h="100vh"
            w="100vw"
          >
            {isLoaded ? (
              <Box position="absolute" left={0} top={0} h="100%" w="100%">
                <GoogleMap
                  center={from}
                  zoom={15}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={(map) => setMap(map)}
                >
                  <Marker position={from} />
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </Box>
            ) : (
              <div>loading...</div>
            )}
            <Box
              p={4}
              borderRadius="lg"
              mt={4}
              bgColor="white"
              shadow="base"
              minW="container.md"
              zIndex="1"
            >
              <HStack spacing={2} justifyContent="center">
                <ButtonGroup>
                  <Button
                    colorScheme="pink"
                    type="submit"
                    onClick={calculateRoute}
                    className="flex px-4 rounded text-primary bg-tealButton hover:bg-tealButton text-lg capitalize"
                  >
                    view bus
                  </Button>
                </ButtonGroup>
              </HStack>
              <HStack spacing={4} mt={4} justifyContent="space-between">
                <Text>Distance: {distance} </Text>
                <Text>Duration: {duration} </Text>
                <IconButton
                  aria-label="center back"
                  icon={<FaLocationArrow />}
                  isRound
                  onClick={() => {
                    map.panTo(to);
                    map.setZoom(15);
                  }}
                />
              </HStack>
            </Box>
          </Flex>
        </div>
        <div className="w-full">
          <div className="border min-w-0  flex-2  lg:flex-3  h-full  flex items-center justify-center mx-2 ">
            <article className="rounded-lg  transition w-full md:flex ">
              <img alt="" src={busTracking} className="w-full object-cover" />

              <div className="bg-white  sm:py-2 item-center mt-4">
                <p className="text-gray-600">
                  How Does the Bus Tracking System Work?
                </p>

                <h3 className=" text-xl text-gray-900 pr-2">
                  EplusApp Digital bus tracking system will allow you to track
                  your bus location after you enter your EplusApp Booking ID and
                  email ID or mobile number details in the search box. Check the
                  schedule timings of the bus route and enjoy your travel with
                  best communication protocols for your end to end TravelSafety
                </h3>
              </div>
            </article>{" "}
          </div>
        </div>
      </div>
      <BackTotop />
    </Layout>
  );
};
export default Ticketing;
